import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ZeroIdSymbol = () => {
    return (
        <div className="w-full h-40 flex flex-row items-center">
            <div className="flex-1 h-10 bg-white -mr-2"></div>
            <div className="w-64 h-64 rounded-full border-[40px] border-white"></div>
            <div className="flex-1 h-10 bg-white -ml-2"></div>
        </div>
    );
};

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    // Load email and password from localStorage on component mount
    useEffect(() => {
        const storedEmail = localStorage.getItem('email');
        const storedPassword = localStorage.getItem('password'); // Load password

        if (storedEmail) {
            setEmail(storedEmail);
        }
        if (storedPassword) {
            setPassword(storedPassword); // Load password
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        // Save email and password to localStorage
        localStorage.setItem('email', email);
        localStorage.setItem('password', password); // Save password

        // Proceed with navigation or authentication
        navigate('/dashboard', { state: { email: email } });
        setEmail('');
        setPassword('');
    };

    return (
        <div className="relative min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-gray-900 via-purple-900 to-black text-white">

            {/* Animated Gradient Background */}
            <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-pink-600 opacity-20 pointer-events-none"></div>

            {/* Particles */}
            <div className="absolute inset-0 overflow-hidden pointer-events-none">
                <div className="particles"></div>
            </div>

            <div className="w-full -mt-24 flex flex-col items-center gap-12 relative z-10">
                <ZeroIdSymbol />
                <div className="mt-840 w-full flex flex-col items-center">
                    <p className="text-white text-9xl titillium-web-regular">ZeroID</p>
                </div>

                {/* Login Form Container */}
                <div className="bg-gradient-to-r from-purple-600 to-pink-500 p-10 rounded-lg shadow-2xl max-w-md w-full">
                    <form onSubmit={handleSubmit}>
                        {/* Email Input */}
                        <div className="mb-5">
                            <label htmlFor="email" className="block text-white text-sm font-semibold mb-2">Email</label>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(entry) => setEmail(entry.target.value)}
                                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition-all duration-300 text-black"
                                placeholder="Enter your email"
                            />
                        </div>

                        {/* Password Input */}
                        <div className="mb-6">
                            <label htmlFor="password" className="block text-white text-sm font-semibold mb-2">Password</label>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(entry) => setPassword(entry.target.value)}
                                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition-all duration-300 text-black"
                                placeholder="Enter your password"
                            />
                        </div>

                        {/* Confirm Button */}
                        <button
                            type="submit"
                            className="w-full bg-black text-white py-3 rounded-lg font-semibold hover:bg-gray-600 transition-colors duration-300"
                        >
                            Confirm
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
