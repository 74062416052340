import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-gradient-to-r from-purple-600 to-pink-500 text-white text-center py-6">
            <p className="text-lg">&copy; {new Date().getFullYear()} ZeroID. All rights reserved.</p>
        </footer>
    );
};

export default Footer;