import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ZeroIdSymbol = () => {
    return (
      <div className="w-full h-40 flex flex-row items-center">
        <div className="flex-1 h-10 bg-white -mr-2"></div>
        <div className="w-64 h-64 rounded-full border-[40px] border-white"></div>
        <div className="flex-1 h-10 bg-white -ml-2"></div>
      </div>
    );
  };

  const TitleBlock = () => {
    return (
        <div className="mt-840 w-full flex flex-col items-center">
            <p className="text-white text-9xl titillium-web-regular ">ZeroID</p>
            <p className="mt-2v text-white text-[45px] titillium-web-extralight">One World, One ID</p>
        </div>
    );
  };


const HomePage = () => {
    const navigate = useNavigate();
    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => {
        setFadeIn(true);
    }, []);

    const handleButtonClick = () => {
        navigate('/login');
    };

    return (
        <div className={`relative min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-gray-900 via-purple-900 to-black text-white transition-opacity duration-1000 ${fadeIn ? 'opacity-100' : 'opacity-0'}`}>

            {/* Animated Gradient Background */}
            <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-pink-600 opacity-20 animate-gradient-move pointer-events-none"></div>

            {/* Particles */}
            <div className="absolute inset-0 overflow-hidden">
                <div className="particles"></div>
            </div>

            <div className="w-full  -mt-24 flex flex-col items-center gap-12">
                <ZeroIdSymbol/> 
                <TitleBlock/>
                <button className="w-[300px] relative mt-10 px-8 py-4 bg-gradient-to-r from-pink-500 to-purple-600 rounded-full text-lg font-semibold shadow-lg transform hover:scale-105 transition-all duration-300 hover:shadow-2xl hover:bg-gradient-to-r hover:from-purple-600 hover:to-pink-500 z-10" onClick={handleButtonClick}>
                    Get Started
                </button>
            </div>
            {/* Call-to-Action Button with Fancy Hover Effects
            <button className="relative mt-10 px-8 py-4 bg-gradient-to-r from-pink-500 to-purple-600 rounded-full text-lg font-semibold shadow-lg transform hover:scale-105 transition-all duration-300 hover:shadow-2xl hover:bg-gradient-to-r hover:from-purple-600 hover:to-pink-500 z-10" onClick={handleButtonClick}>
                Get Started
            </button> */}

        </div>
    );
};
export default HomePage;
