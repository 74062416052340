import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/Login';
import ProverPage from './pages/Prover';
import MessengerPage from './pages/Messenger';
import HomePage from './pages/Home';
import Dashboard from './pages/Dashboard';
import Footer from './pages/Footer';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/login" element={<><LoginPage /><Footer /></>} />
        <Route path="/prover" element={<><ProverPage /><Footer /></>} />
        <Route path="/messenger" element={<><MessengerPage /><Footer /></>} />
        <Route path="/dashboard" element={<><Dashboard /><Footer /></>} />
        <Route path="/" element={<HomePage />} />
      </Routes>
    </Router>
  </React.StrictMode >
);

reportWebVitals();
