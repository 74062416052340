import React, { useEffect, useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

const Dashboard = () => {
    // Get sessionId from query parameters
    const params = new URLSearchParams(window.location.search);
    const sessionId = params.get('sessionId');

    // State for fade-in effect
    const [fadeIn, setFadeIn] = useState(false);

    // State for soulBoundTokens
    const [soulBoundTokens, setSoulBoundTokens] = useState([
        {
            name: 'AXA',
            address: '0x9839ec937381c850a889c72b685ea5baecf8b31f',
            issuer: 'AXA Investment Managers',
            etherscanUrl: 'https://sepolia.etherscan.io/tx/0xf947e207e15b763b3a5c441bb9655f6cd19c9601018de8ee58b8c7ef47fc156d',
        },
        {
            name: 'FT',
            address: '0x1474cf49e417ef5dedee0decd09ca36cd387a737',
            issuer: 'Franklin Templeton',
            etherscanUrl: 'https://sepolia.etherscan.io/tx/0xb1c0eb28f10634655c9d3c86cc4fd7b25c00d099db9f8ec2a8318bf6f99e6de3',
        },
    ]);

    const effectRan = useRef(false); // Ref to track if useEffect has run

    useEffect(() => {
        if (effectRan.current) {
            // If effect has already run, exit early
            return;
        }
        effectRan.current = true; // Mark effect as run

        setFadeIn(true);

        if (sessionId) {
            const txURL = localStorage.getItem(`tx-${sessionId}`);
            if (txURL) {
                // Check if DNXT is already in the tokens
                const dnxtExists = soulBoundTokens.some(token => token.name === 'DNXT');
                if (!dnxtExists) {
                    setSoulBoundTokens(prevTokens => [
                        {
                            name: 'DNXT',
                            address: '0xbde3f63813c30b8fcfd942b78a89433257daed43',
                            issuer: 'DNXT',
                            etherscanUrl: txURL,
                        },
                        ...prevTokens,
                    ]);
                }
            }
        }
    }, [sessionId, soulBoundTokens]);

    // Handler for registering DNXT
    const handleRegisterDNXT = () => {
        console.log('Registering for DNXT...');
        const uuid = uuidv4();
        window.location.href = `/messenger?sessionId=${uuid}`;
    };

    // Helper function to shorten addresses and transaction hashes
    const shortenHash = (hash) => {
        return `${hash.slice(0, 6)}...${hash.slice(-4)}`;
    };

    return (
        <div
            className={`relative min-h-screen flex flex-col items-center justify-center bg-gray-900 text-white transition-opacity duration-1000 ${fadeIn ? 'opacity-100' : 'opacity-0'
                }`}
        >
            {/* Logout Button */}
            <button
                onClick={() => window.location.href = '/'}
                className="absolute top-4 right-4 px-4 py-2 bg-red-500 hover:bg-red-600 text-white font-semibold rounded-lg shadow-md focus:outline-none"
            >
                Logout
            </button>

            {/* Main Heading with Fancy Gradient and Floating Animation */}
            <h1 className="text-6xl md:text-7xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-pink-500 mb-8 animate-float">
                Dashboard
            </h1>

            {/* Subtitle with Fade-in Effect */}
            <p
                className={`text-lg md:text-xl text-gray-300 mb-10 ${fadeIn ? 'opacity-1' : 'opacity-0'
                    } animate-fade-in animation-delay-500`}
            >
                Soul-Bound Access Tokens
            </p>

            {/* Soul-Bound Tokens Container with Hover Shadow */}
            <div className="bg-gray-800 rounded-lg shadow-lg p-8 w-full max-w-md text-center space-y-6 transform transition-transform duration-500 hover:scale-105">
                {/* Conditionally render "Register for DNXT" Button */}
                {!sessionId && (
                    <button
                        onClick={handleRegisterDNXT}
                        className="w-full px-4 py-2 bg-gradient-to-r from-pink-500 to-purple-600 rounded-lg text-left text-white font-semibold hover:shadow-xl transition-shadow duration-300"
                    >
                        Register for DNXT
                    </button>
                )}

                <h2 className="text-2xl font-semibold mb-4">Your Tokens</h2>

                {/* Divider */}
                <div className="border-t border-gray-700 my-4"></div>

                {/* Display each registered soul-bound token with Entry Animation */}
                <div className="space-y-4">
                    {soulBoundTokens.map((token, index) => (
                        <div
                            key={`${token.name}-${token.address}`}
                            className="bg-gray-700 rounded-lg p-4 text-left text-gray-200 animate-slide-in"
                            style={{ animationDelay: `${0.2 * index}s` }}
                        >
                            <div className="grid grid-cols-2 gap-y-2">
                                <span className="font-semibold">Issuer:</span>
                                <span className="text-sm text-gray-400">{token.issuer}</span>

                                <span className="font-semibold">Token Name:</span>
                                <span className="text-sm text-gray-400">{token.name}</span>

                                <span className="font-semibold">Address:</span>
                                <span className="text-sm text-gray-400 break-all">
                                    <a
                                        href={`https://sepolia.etherscan.io/address/${token.address}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-blue-400 hover:underline font-mono"
                                    >
                                        {shortenHash(token.address)}
                                    </a>
                                </span>

                                <span className="font-semibold">Etherscan TX:</span>
                                <span className="text-sm text-gray-400 break-all">
                                    <a
                                        href={token.etherscanUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-blue-400 hover:underline font-mono"
                                    >
                                        {shortenHash(token.etherscanUrl.split('/').pop())}
                                    </a>
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* CSS Animations */}
            <style>
                {`
                /* Floating animation for main heading */
                @keyframes float {
                    0%, 100% {
                        transform: translateY(0);
                    }
                    50% {
                        transform: translateY(-10px);
                    }
                }
                .animate-float {
                    animation: float 4s ease-in-out infinite;
                }

                /* Fade-in animation for subtitle */
                @keyframes fade-in {
                    from {
                        opacity: 0;
                        transform: translateY(10px);
                    }
                    to {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
                .animate-fade-in {
                    animation: fade-in 1s ease-out forwards;
                }
                
                /* Delay for fade-in animation */
                .animation-delay-500 {
                    animation-delay: 0.5s;
                }

                /* Slide-in animation for token items */
                @keyframes slide-in {
                    from {
                        opacity: 0;
                        transform: translateX(-20px);
                    }
                    to {
                        opacity: 1;
                        transform: translateX(0);
                    }
                }
                .animate-slide-in {
                    animation: slide-in 0.7s ease-out forwards;
                }
                `}
            </style>
        </div>
    );
};

export default Dashboard;
