import React from 'react';

const LoadingSpinner = () => {
    return (
        <div className="flex items-center justify-center">
            {/* Loading spinner */}
            <div className="relative w-24 h-24 flex items-center justify-center">
                {/* Outer ring with rotation and gradient */}
                <div className="absolute w-full h-full border-8 border-t-transparent border-b-transparent border-r-purple-500 border-l-pink-500 rounded-full animate-spin-fast"></div>

                {/* Inner pulsing ring */}
                <div className="absolute w-16 h-16 border-4 border-purple-600 rounded-full opacity-75 animate-pulse"></div>

                {/* Center core */}
                <div className="w-8 h-8 bg-gradient-to-r from-purple-600 to-pink-500 rounded-full"></div>
            </div>

            {/* CSS Animations */}
            <style>
                {`
                /* Faster spin for outer ring */
                @keyframes spin {
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(360deg);
                    }
                }
                .animate-spin-fast {
                    animation: spin 1s linear infinite;
                }

                /* Pulse animation for inner ring */
                @keyframes pulse {
                    0%, 100% {
                        opacity: 0.5;
                    }
                    50% {
                        opacity: 1;
                    }
                }
                .animate-pulse {
                    animation: pulse 1.5s ease-in-out infinite;
                }
                `}
            </style>
        </div>
    );
};

export default LoadingSpinner;