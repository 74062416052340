import React, { useState, useEffect, useRef } from 'react';
import LoadingSpinner from './Spinner';

const ProverPage = () => {
    const messages = [
        { text: 'Contacting JP Morgan...', duration: 3000 },
        { text: 'Collecting Personal Data...', duration: 3000 },
        { text: 'Generating Proof...', duration: 3000 },
        { text: 'Validating Proof with DNXT...', duration: 3000 },
    ];

    const [status, setStatus] = useState('Text Message Received');
    const [isComplete, setIsComplete] = useState(false);
    const [showInitialCheckmark, setShowInitialCheckmark] = useState(true);
    const effectRan = useRef(false); // Ref to track effect execution

    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get('sessionId');

    const email = localStorage.getItem('email');
    const password = localStorage.getItem('password');

    useEffect(() => {
        if (effectRan.current) {
            // If effect has already run, exit early
            return;
        }
        effectRan.current = true; // Mark effect as run
        console.log('ProverPage mounted');

        const startProcess = async () => {
            // Show the initial checkmark for 3 seconds
            await new Promise((resolve) => setTimeout(resolve, 3000));
            setShowInitialCheckmark(false);

            // Proceed with the rest of the messages
            for (let i = 0; i < messages.length; i++) {
                setStatus(messages[i].text);

                // Wait for the specified duration before showing the next message
                await new Promise((resolve) => setTimeout(resolve, messages[i].duration));
            }

            // Send POST request to '/backend/submit-tx'
            setStatus('Submitting Proof to Blockchain...');
            const response = await fetch('/backend/submit-tx', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username: email, password: password }),
            });

            if (response.ok) {
                const url = await response.text();
                localStorage.setItem(`tx-${sessionId}`, url);
                // Indicate completion and show the final checkmark
                setIsComplete(true);
            } else {
                // Handle error case
                setStatus('Failed to submit proof. Please try again.');
            }
        };

        startProcess();
    }, [email, password]);

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 text-white space-y-6">
            {showInitialCheckmark ? (
                <>
                    {/* Initial checkmark and message */}
                    <h2 className="text-3xl md:text-4xl font-extrabold text-green-500">
                        Text Message Received
                    </h2>
                    <div className="w-40 h-40 flex items-center justify-center bg-green-500 rounded-full animate-scale-in">
                        <svg
                            className="w-24 h-24 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={3}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M5 13l4 4L19 7"
                            />
                        </svg>
                    </div>
                </>
            ) : !isComplete ? (
                <>
                    {/* Status message and spinner during processing */}
                    <h2 className="text-3xl md:text-4xl font-extrabold text-gray-200">
                        {status}
                    </h2>
                    <LoadingSpinner />
                </>
            ) : (
                <>
                    {/* Final checkmark and message */}
                    <h2 className="text-3xl md:text-4xl font-extrabold text-green-500">
                        Proof Submitted!
                    </h2>
                    <div className="w-40 h-40 flex items-center justify-center bg-green-500 rounded-full animate-scale-in">
                        <svg
                            className="w-24 h-24 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={3}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M5 13l4 4L19 7"
                            />
                        </svg>
                    </div>

                    {/* "Return to Dashboard" button */}
                    <button
                        onClick={() => window.location.href = `/dashboard?sessionId=${sessionId}`}
                        className="px-8 py-3 text-lg font-bold text-white bg-gradient-to-r from-purple-600 to-pink-500 rounded-full shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl focus:outline-none focus:ring-4 focus:ring-purple-600"
                    >
                        Return to Dashboard
                    </button>
                </>
            )}

            {/* CSS Animations */}
            <style>
                {`
                /* Scale-in animation for checkmark */
                @keyframes scale-in {
                    0% {
                        transform: scale(0.7);
                        opacity: 0;
                    }
                    100% {
                        transform: scale(1);
                        opacity: 1;
                    }
                }
                .animate-scale-in {
                    animation: scale-in 0.6s ease-out forwards;
                }
                `}
            </style>
        </div>
    );
};

export default ProverPage;
